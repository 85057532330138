import React, { useEffect, useState, useRef } from "react";
import { Container, Input, Row, Col, Modal, Form, Label, Nav, NavItem, NavLink } from "reactstrap";
import SearchContainer from "../../components/Common/SearchContainer";
import DataTableContainer from "../../components/Common/DataTableContainer";
import Pagination from "../../components/Common/Pagination";
import Moment from "moment";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import postpaid from "../../services/PostpaidService"
import classnames from "classnames";
import { ModalConfirmation, ModalInfo } from "../../components/Common/ModalCustoms";

const PostpaidSettings = () => {
    const [active_tab, setActiveTab] = useState("1");
    const [param_tab, setParamTab] = useState("");
    const [list_postpaid, setListPostpaid] = useState([])
    const [view_postpaid, setViewPostpaid] = useState("");
    const [view_modal, setview_modal] = useState(false);
    const [filtered, setFiltered] = useState([])
    const [searchTerm, setSearchTerm] = useState('')
    const [currentSize, setCurrentSize] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
    const [perPage, setPerPage] = useState(1)
    const webConfig = JSON.parse(localStorage.getItem("webConfig"))

    if (!localStorage.getItem("authUser").includes("ROLE_DEVELOPER") && !localStorage.getItem("authUser").includes("ROLE_ADMIN")) {
        window.location.replace('/panel/error-403')
    }

    useEffect(() => {
        const controller = new AbortController()
        initData(10, 1, searchTerm, param_tab)
        return () => controller.abort()
    }, [])

    const numberFormat = (value) =>
        new Intl.NumberFormat('id-ID', {
            style: 'currency',
            currency: 'IDR'
        }).format(value).replace(',00', '');

    const initData = async (size, page, search, status_product) => {
        try {
            const result = await postpaid.all_postpaid_product(size, page, search, status_product)
            const formattedData = result.data.map((item, index) => ({
                ...item,
                no: <div style={{ textAlign: "center" }}>{item.no}</div>,
                admin_fee: numberFormat(item.admin),
                commission: numberFormat(item.commission),
                profits: numberFormat(item.profits),
                seller_product_status: (
                    <span className={`badge-soft-${getStatusBadgeClass(item.seller_product_status)} me-1 badge badge-secondary badge-pill`}>
                        {getStatusText(item.seller_product_status)}
                    </span>
                ),
            }))
            setPerPage(result.total_pages)
            setCurrentPage(result.current_page)
            setListPostpaid(formattedData)
            setFiltered(formattedData)
        } catch (error) {
            const errorCode = JSON.stringify(error.response.data.status)
            if (errorCode.toString() === "401") {
                window.location.replace('/panel/logout')
            } else if (errorCode.toString() === "403") {
                window.location.reload()
            }
        }
    };

    const toggle = (tab) => {
        if (active_tab !== tab) {
            setActiveTab(tab);
            if (tab == 1) {
                setParamTab("")
                initData(currentSize, currentPage, searchTerm, "")
            } else if (tab == 2) {
                setParamTab("true")
                initData(currentSize, currentPage, searchTerm, "true")
            } else if (tab == 3) {
                setParamTab("false")
                initData(currentSize, currentPage, searchTerm, "false")
            }
        }
    };

    const handleClickSync = (event, values) => {
        ModalConfirmation(
            "You want to sync this data?",
            "",
            "warning",
            "postpaid settings",
            () => processClickSynchronize(),
        )
    }

    const processClickSynchronize = async () => {
        try {
            await postpaid.synchronize_data()
            ModalInfo(
                "Success",
                "Updated product postpaid success.",
                "success",
                false
            )
            initData(currentSize, currentPage, searchTerm, param_tab)
        } catch (error) {
            const errorCode = JSON.stringify(error.response?.data?.status)
            if (errorCode === "401") {
                window.location.replace('/panel/logout')
            } else if (errorCode === "403") {
                window.location.reload()
            } else {
                console.error('An unexpected error occurred:', error)
            }
        }
    }

    const getStatusBadgeClass = (status) => {
        switch (status) {
            case true:
                return "success"
            case false:
                return "danger"
            default:
                return "secondary"
        }
    };

    const getStatusText = (status) => {
        switch (status) {
            case true:
                return "Product Active"
            case false:
                return "Product Not Active"
            default:
                return "Unknown"
        }
    };

    const columns = [
        {
            name: 'No.',
            selector: row => row.no,
            sortable: true,
            center: "true",
            style: {
                textAlign: 'center',
            },
            width: '6%',
        },
        {
            name: 'Product Name',
            selector: row => row.product_name,
            sortable: true,
            width: '26%',
        },
        {
            name: 'Admin Fee',
            selector: row => row.admin_fee,
            sortable: true,
            width: '10%',
        },
        {
            name: 'Commission',
            selector: row => row.commission,
            sortable: true,
            width: '10%',
        },
        {
            name: 'Profits',
            selector: row => row.profits,
            sortable: true,
            width: '10%',
        },
        {
            name: 'SKU Code',
            selector: row => row.buyer_sku_code,
            sortable: true,
            width: '15%',
        },
        {
            name: 'Seller Status',
            selector: row => row.seller_product_status,
            sortable: true,
            width: '12%',
        },
        {
            name: "Actions",
            selector: row => row.actions,
            sortable: false,
            cell: rankFormatter,
        }
    ];

    function rankFormatter(cell, row, rowIndex, formatExtraData) {
        return (
            <div
                style={{
                    textAlign: "left",
                }}>
                <button type="button" className="btn btn-soft-dark waves-effect waves-light" onClick={() => { handleClickView(cell.id) }} data-toggle="modal"><i className="bx bx-show font-size-16 align-middle"></i></button>
            </div>
        );
    }

    function removeBodyCss() {
        document.body.classList.add("no_padding")
    }

    function handleClickClose(action) {
        if (action === "view") {
            setview_modal(false)
        } else if (action === "topup") {
            settopup_modal(false)
        }
    }

    function handleClickView(id) {
        const numberFormat = (value) =>
            new Intl.NumberFormat('id-ID', {
                style: 'currency',
                currency: 'IDR'
            }).format(value).replace(',00', '');
        postpaid.view_postpaid(id).then(
            (data) => {
                var obj = data;
                setview_modal(!view_modal)
                removeBodyCss()
                Object.assign(obj, { admin: numberFormat(data.admin), })
                Object.assign(obj, { commission: numberFormat(data.commission), })
                Object.assign(obj, { profits: numberFormat(data.profits), })
                Object.assign(obj, {
                    seller_product_status: (
                        <span className={`badge-soft-${getStatusBadgeClass(data.seller_product_status)} me-1 badge badge-secondary badge-pill`}>
                            {getStatusText(data.seller_product_status)}
                        </span>
                    ),
                })
                Object.assign(obj, {
                    buyer_product_status: (
                        <span className={`badge-soft-${getStatusBadgeClass(data.buyer_product_status)} me-1 badge badge-secondary badge-pill`}>
                            {getStatusText(data.buyer_product_status)}
                        </span>
                    ),
                })
                setViewPostpaid(data)
                return Promise.resolve()
            },
            (error) => {
                const errorCode = JSON.stringify(error.response.data.status);
                if (errorCode.toString() === "401") {
                    window.location.replace('/panel/logout')
                } else if (errorCode.toString() === "403") {
                    window.location.reload()
                }
            }
        )
    }

    const handleSizeChange = size => {
        setCurrentSize(size)
        initData(size, currentPage, searchTerm, param_tab)
    };

    const handlePageChange = page => {
        setCurrentPage(page)
        initData(currentSize, page, searchTerm, param_tab)
    };

    const handleSearch = event => {
        setSearchTerm(event.target.value)
        initData(currentSize, currentPage, event.target.value, param_tab)
        setFiltered(list_postpaid)
    };

    document.title = `Postpaid Settings | ${webConfig[0].name} - ${webConfig[0].title}`;

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Product" breadcrumbItem="Postpaid Settings" />
                    <Nav tabs>
                        <NavItem>
                            <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                    active: active_tab === "1",
                                })}
                                onClick={() => {
                                    toggle("1");
                                }}
                            >
                                All Product
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                    active: active_tab === "2",
                                })}
                                onClick={() => {
                                    toggle("2");
                                }}
                            >
                                Product Active
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                    active: active_tab === "3",
                                })}
                                onClick={() => {
                                    toggle("3");
                                }}
                            >
                                Product Not Active
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <br />
                    <Row className="mb-3">
                        <Col md="10">
                            <button type="button" id="btnAdd" onClick={() => { handleClickSync() }} data-toggle="modal" className="btn btn-primary waves-effect btn-label waves-light"><i className="bx bx-sync label-icon"></i> Synchrinize</button>
                        </Col>
                        <SearchContainer
                            isFilter={true}
                            searchTerm={searchTerm}
                            fieldName={""}
                            handleSearch={handleSearch}
                        />
                    </Row>
                    <DataTableContainer
                        columns={columns}
                        data={filtered}
                        currentPage={currentPage}
                        perPage={perPage}
                        handlePageChange={handlePageChange}
                        searchTerm={searchTerm}
                        handleSearch={handleSearch}
                    />

                    <Pagination
                        isPageSize={true}
                        isPagination={true}
                        currentPage={currentPage}
                        totalPages={perPage}
                        onPageChange={handlePageChange}
                        onSizeChange={handleSizeChange}
                    />
                </Container>
                <Modal
                    isOpen={view_modal}
                    toggle={() => {
                        handleClickView()
                    }}
                    backdrop={'static'}
                    id="staticBackdrop"
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">
                            View
                        </h5>
                        <button
                            type="button"
                            onClick={() =>
                                handleClickClose("view")
                            }
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <Col xl="4">
                                ID
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="7">
                                {view_postpaid.id}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="4">
                                Product Name
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="7">
                                {view_postpaid.product_name}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="4">
                                Category
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="7">
                                {view_postpaid.category}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="4">
                                Brand
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="7">
                                {view_postpaid.brand}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="4">
                                Seller Name
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="7">
                                {view_postpaid.seller_name}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="4">
                                Admin Fee
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="7">
                                {view_postpaid.admin}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="4">
                                Commission
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="7">
                                {view_postpaid.commission}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="4">
                                Profit
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="7">
                                {view_postpaid.profits}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="4">
                                SKU Code
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="7">
                                {view_postpaid.buyer_sku_code}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="4">
                                Buyer Product Status
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="7">
                                {view_postpaid.buyer_product_status}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="4">
                                Seller Product Status
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="7">
                                {view_postpaid.seller_product_status}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="4">
                                Description
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="7">
                                {view_postpaid.description}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="4">
                                Created Date
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="7">
                                {Moment(view_postpaid.created_date).utc().format('yyyy-MM-DD hh:mm:ss')}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="4">
                                Updated Date
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="7">
                                {Moment(view_postpaid.updated_date).utc().format('yyyy-MM-DD hh:mm:ss')}
                            </Col>
                            <br /><br />
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={() =>
                                    handleClickClose("view")
                                }
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </Modal>
            </div >
        </React.Fragment >
    )
};

export default PostpaidSettings;
