import React, { useEffect, useState } from "react";
import { Container, Input, Row, Col, Modal, Form, Label } from "reactstrap";
import ButtonContainer from "../../components/Common/ButtonContainer";
import SearchContainer from "../../components/Common/SearchContainer";
import DataTableContainer from "../../components/Common/DataTableContainer";
import Pagination from "../../components/Common/Pagination";
import Moment from "moment";
import Breadcrumbs from "../../components/Common/Breadcrumb";
// import History_infaq from "../../services/HistoryInfaq"
import { useFormik } from "formik"
import Mob_users from "../../services/MobUserService"
import { ModalConfirm, ModalInfo } from "../../components/Common/ModalCustoms"

const UserSettings = () => {
    const [full_name, setFullName] = useState('');
    const [list_user, setListUser] = useState([])
    const [data_roles, setDataRoles] = useState([]);
    const [topup_user, setTopupUser] = useState("");
    const [topup_modal, settopup_modal] = useState(false);
    const [view_user, setViewUser] = useState("");
    const [view_modal, setview_modal] = useState(false);
    const [edit_user, setEditUser] = useState("");
    const [edit_modal, setedit_modal] = useState(false);
    const [amount, setAmount] = useState('');
    const [filtered, setFiltered] = useState([])
    const [searchTerm, setSearchTerm] = useState('')
    const [currentSize, setCurrentSize] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
    const [perPage, setPerPage] = useState(1)
    const webConfig = JSON.parse(localStorage.getItem("webConfig"))

    if (!localStorage.getItem("authUser").includes("ROLE_DEVELOPER") && !localStorage.getItem("authUser").includes("ROLE_ADMIN")) {
        window.location.replace('/panel/error-403')
    }

    useEffect(() => {
        if (localStorage.getItem("authUser")) {
            const obj = JSON.parse(localStorage.getItem("authUser"));
            setFullName(obj.full_name);
        }
        const controller = new AbortController()
        var btnDelete = document.getElementById("btnDelete")
        btnDelete.style.visibility = "hidden"
        initData(10, 1, searchTerm)
        return () => controller.abort()
    }, [])

    const maxAmount = "20000000";
    const parsedAmount = parseInt(maxAmount);

    const numberFormat = (value) =>
        new Intl.NumberFormat('id-ID', {
            style: 'currency',
            currency: 'IDR'
        }).format(value).replace(',00', '');

    const initData = async (size, page, search) => {
        try {
            const result = await Mob_users.all_users(size, page, search)
            const formattedData = result.data.map((item, index) => ({
                ...item,
                no: <div style={{ textAlign: "center" }}>{item.no}</div>,
                balance: numberFormat(item.balance),
                status: (
                    <span className={`badge-soft-${getStatusBadgeClass(item.is_active)} me-1 badge badge-secondary badge-pill`}>
                        {getStatusText(item.is_active)}
                    </span>
                )
            }))
            setPerPage(result.total_pages)
            setCurrentPage(result.current_page)
            setListUser(formattedData)
            setFiltered(formattedData)
        } catch (error) {
            const errorCode = JSON.stringify(error.response.data.status)
            if (errorCode.toString() === "401") {
                window.location.replace('/panel/logout')
            } else if (errorCode.toString() === "403") {
                window.location.reload()
            }
        }
    };

    function rankFormatter(row) {
        if (row.is_active === 2) {
            return (
                <div style={{ textAlign: "left" }}>
                    <button type="button" className="btn btn-soft-success waves-effect waves-light" onClick={() => { handleClickTopup(row.id) }} data-toggle="modal" style={{ marginRight: '10px' }}>
                        <i className="bx bx-wallet font-size-16 align-middle"></i>
                    </button>
                    <button type="button" className="btn btn-soft-dark waves-effect waves-light" onClick={() => { handleClickView(row.id) }} data-toggle="modal" style={{ marginRight: '10px' }}>
                        <i className="bx bx-show font-size-16 align-middle"></i>
                    </button>
                    <button type="button" className="btn btn-soft-primary waves-effect waves-light" onClick={() => { handleClickEdit(row.id) }} data-toggle="modal" style={{ marginRight: '10px' }}>
                        <i className="bx bx-edit font-size-16 align-middle"></i>
                    </button>
                    <button type="button" className="btn btn-soft-danger waves-effect waves-light" onClick={() => { handleClickDeleteOne(row.id) }}>
                        <i className="bx bx-trash font-size-16 align-middle"></i>
                    </button>
                </div>
            )
        } else {
            return (
                <div style={{ textAlign: "left" }}>
                    <button type="button" className="btn btn-soft-dark waves-effect waves-light" onClick={() => { handleClickView(row.id) }} data-toggle="modal" style={{ marginRight: '10px' }}>
                        <i className="bx bx-show font-size-16 align-middle"></i>
                    </button>
                    <button type="button" className="btn btn-soft-primary waves-effect waves-light" onClick={() => { handleClickEdit(row.id) }} data-toggle="modal" style={{ marginRight: '10px' }}>
                        <i className="bx bx-edit font-size-16 align-middle"></i>
                    </button>
                    <button type="button" className="btn btn-soft-danger waves-effect waves-light" onClick={() => { handleClickDeleteOne(row.id) }}>
                        <i className="bx bx-trash font-size-16 align-middle"></i>
                    </button>
                </div>
            )
        }
    };

    const handleChangeTopup = (e) => {
        const rawValue = e.target.value;
        const formattedValue = formatCurrency(rawValue);
        setAmount(formattedValue);
        const amountReplace = formattedValue.replace(/\./g, '');
        validationTopupType.setFieldValue("amount", amountReplace)
        validationTopupType.setFieldValue("admin_name", full_name)
    }

    const formatCurrency = (value) => {
        const numericValue = value.replace(/[^0-9]/g, '');
        const formattedValue = Number(numericValue).toLocaleString('id-ID');
        return formattedValue;
    };

    function handleClickCheckboxes() {
        let checkedBoxes = document.querySelectorAll('input[type=checkbox]:checked');
        var btnDelet = document.getElementById("btnDelete");
        if (checkedBoxes.length > 1) {
            btnDelet.style.visibility = 'visible';
        } else if (checkedBoxes.length < 1) {
            btnDelet.style.visibility = 'hidden';
        } else {
            btnDelet.style.visibility = 'hidden';
        }
    }

    function handleClickClose(action) {
        if (action === "view") {
            setview_modal(false)
        } else if (action === "edit") {
            setedit_modal(false)
        } else if (action === "topup") {
            settopup_modal(false)
        }
    }

    const checkedBoxesFormatter = (row) => {
        return (
            <div style={{ textAlign: "center" }}>
                <input type="checkbox" value={row.id} onClick={() => { handleClickCheckboxes(row.id) }} />
            </div>
        )
    };

    const columns = [
        {
            name: '#',
            selector: row => row.checkbox,
            sortable: false,
            cell: checkedBoxesFormatter,
            center: true,
            style: {
                textAlign: 'center',
            },
            width: '5%',
        },
        {
            name: 'No.',
            selector: row => row.no,
            sortable: true,
            center: true,
            style: {
                textAlign: 'center',
            },
            width: '7%',
        },
        {
            name: 'Full Name',
            selector: row => row.full_name,
            sortable: true,
            style: {
                textAlign: 'left',
            },
            width: '14%',
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
            style: {
                textAlign: 'left',
            },
            width: '14%',
        },
        {
            name: 'Phone',
            selector: row => row.phone,
            sortable: true,
            style: {
                textAlign: 'left',
            },
            width: '14%',
        },
        {
            name: 'Role Name',
            selector: row => row.roles[0].role_name,
            sortable: true,
            style: {
                textAlign: 'left',
            },
            width: '9%',
        },
        {
            name: 'Saldo User',
            selector: row => row.balance,
            sortable: true,
            style: {
                textAlign: 'right',
            },
            width: '9%',
        },
        {
            name: 'Status',
            selector: row => row.status,
            sortable: true,
            style: {
                textAlign: 'center',
            },
            width: '8%',
        },
        {
            name: "Actions",
            selector: row => row.actions,
            sortable: false,
            cell: rankFormatter,
            width: '20%',
        }
    ];

    const getStatusBadgeClass = (status) => {
        switch (status) {
            case 2:
                return "success"
            case 3:
                return "primary"
            case 1:
                return "dark"
            default:
                return "secondary"
        }
    };

    const getStatusText = (status) => {
        switch (status) {
            case 2:
                return "Verified"
            case 3:
                return "Blocked"
            case 1:
                return "Not Verified"
            default:
                return "Unknown"
        }
    };

    function removeBodyCss() {
        document.body.classList.add("no_padding")
    }

    const handleClickTopup = async (id) => {
        try {
            const data = await Mob_users.view_users(id)
            const updatedData = { ...data };
            setTopupUser(updatedData)
            settopup_modal(!topup_modal)
            setAmount("")
            removeBodyCss()
        } catch (error) {
            const errorCode = error.response?.data?.status;

            if (errorCode === 401) {
                window.location.replace('/panel/logout')
            } else if (errorCode === 403) {
                window.location.reload()
            } else {
                console.error('An unexpected error occurred:', error)
            }
        }
    };

    const handleClickEdit = async (id) => {
        getRoles()
        try {
            const data = await Mob_users.view_users(id)
            const updatedData = { ...data };
            setEditUser(updatedData)
            setedit_modal(!topup_modal)
            removeBodyCss()
        } catch (error) {
            const errorCode = error.response?.data?.status;

            if (errorCode === 401) {
                window.location.replace('/panel/logout')
            } else if (errorCode === 403) {
                window.location.reload()
            } else {
                console.error('An unexpected error occurred:', error)
            }
        }
    };

    function getRoles() {
        Mob_users.get_roles().then(
            (data) => {
                setDataRoles(data)
                return Promise.resolve()
            },
            (error) => {
                const errorCode = JSON.stringify(error.response.data.status);
                if (errorCode.toString() === "401") {
                    window.location.replace('/panel/logout')
                } else if (errorCode.toString() === "403") {
                    window.location.reload()
                }
            }
        )
    }

    const changeEdit = (e) => {
        const { name, value } = e.target;
        validationEditType.setFieldValue(name, value)
    };

    const handleClickView = async (id) => {
        try {
            const data = await Mob_users.view_users(id)

            // Clone the data to avoid direct mutation
            const updatedData = { ...data };
            var obj = updatedData;
            Object.assign(obj, { role_name: obj.roles[0].role_name })
            // Update status based on is_active
            if (data.is_active === 2) {
                Object.assign(obj, { status: <span className='badge-soft-success me-1 badge badge-secondary badge-pill'> Verified </span> });
            } else if (data.is_active === 3) {
                Object.assign(obj, { status: <span className='badge-soft-danger me-1 badge badge-secondary badge-pill'> Blocked </span> });
            } else if (data.is_active === 1) {
                Object.assign(obj, { status: <span className='badge-soft-dark me-1 badge badge-secondary badge-pill'> Not Verified </span> });
            }

            setViewUser(updatedData)

            setview_modal(!view_modal)
            removeBodyCss()
        } catch (error) {
            const errorCode = error.response?.data?.status;

            if (errorCode === 401) {
                window.location.replace('/panel/logout')
            } else if (errorCode === 403) {
                window.location.reload()
            } else {
                console.error('An unexpected error occurred:', error)
            }
        }
    };

    const handleClickDeleteSelected = () => {
        ModalConfirm(
            "Are you sure?",
            "You won't be able to revert this!",
            "warning",
            "users settings",
            () => processClickDeleteSelected(),
        )
    };

    const processClickDeleteSelected = async () => {
        let checkedBoxes = document.querySelectorAll('input[type=checkbox]:checked')
        let arrayCheck = Array.from(checkedBoxes).map(checkbox => Number.parseInt(checkbox.value))

        if (arrayCheck.length === 0) {
            ModalInfo(
                "No selection",
                "Please select at least one item to delete.",
                "warning",
                false
            )
            return;
        }

        try {
            await Mob_users.multi_delete_users({ id: arrayCheck })
            ModalInfo(
                "Deleted!",
                "Your data has been deleted.",
                "success",
                false
            )
            initData(currentSize, currentPage, searchTerm)
            document.getElementById("btnDelete").style.visibility = "hidden"
        } catch (error) {
            const errorCode = JSON.stringify(error.response?.status)
            if (errorCode === "401") {
                window.location.replace('/panel/logout')
            } else if (errorCode === "403") {
                window.location.reload()
            } else {
                ModalInfo(
                    "Error",
                    "An unexpected error occurred.",
                    "error",
                    false
                )
                console.error('An unexpected error occurred:', error)
            }
        }
    };

    const handleClickDeleteOne = (id) => {
        ModalConfirm(
            "Are you sure?",
            "You won't be able to revert this!",
            "warning",
            "mobile user settings",
            () => processClickDeleteOne(id),
        )
    };

    const processClickDeleteOne = async (id) => {
        try {
            await Mob_users.delete_users(id)
            ModalInfo(
                "Deleted!",
                "Your data has been deleted.",
                "success",
                false
            )
            initData(currentSize, currentPage, searchTerm)
        } catch (error) {
            const errorCode = JSON.stringify(error.response?.status)
            if (errorCode === "401") {
                window.location.replace('/panel/logout')
            } else if (errorCode === "403") {
                window.location.reload()
            } else {
                ModalInfo(
                    "Error",
                    error.response?.data?.message,
                    "error",
                    false
                )
                console.error('An unexpected error occurred:', error)
            }
        }
    };

    const handleSizeChange = size => {
        setCurrentSize(size)
        initData(size, currentPage, searchTerm)
    };

    const handlePageChange = page => {
        setCurrentPage(page)
        initData(currentSize, page, searchTerm)
    };

    const handleSearch = event => {
        setSearchTerm(event.target.value)
        initData(currentSize, currentPage, event.target.value)
        setFiltered(list_user)
    };

    document.title = `Mobile User Settings | ${webConfig[0].name} - ${webConfig[0].title}`;

    const validationTopupType = useFormik({
        enableReinitialize: true,
        initialValues: {
            users_id: topup_user.id,
            destination_number: topup_user.phone,
            saldo_user: topup_user.balance,
            amount: "",
            admin_name: "",
            balance: topup_user.balance,
            is_debt: false
        },
        onSubmit: async (values) => {
            const amount = values.amount;
            const saldoUser = values.saldo_user;
            const amountReplace = amount.replace(/\./g, '');
            const maxAmount = 20000000 - saldoUser;
            try {
                if (amountReplace > maxAmount) {
                    ModalInfo(
                        "Gagal",
                        "Opsss! Anda hanya boleh input maksimal nominal topup sebesar : " + numberFormat(maxAmount),
                        "error",
                        false
                    )
                } else {
                    await Mob_users.topup_saldo(values)
                    handleClickClose("topup")
                    initData(currentSize, currentPage, searchTerm)
                }
            } catch (error) {
                const errorCode = JSON.stringify(error.response?.data?.status)
                if (errorCode === "401") {
                    window.location.replace('/panel/logout')
                } else if (errorCode === "403") {
                    window.location.reload()
                } else {
                    console.error('An unexpected error occurred:', error)
                }
            }
        }
    })

    const validationEditType = useFormik({
        enableReinitialize: true,
        initialValues: {
            full_name: edit_user.full_name,
            email: edit_user.email,
            phone: edit_user.phone,
            password: "",
            pin: "",
            photo: "",
            roles_id: edit_user.roles_id,
            is_active: edit_user.is_active
        },
        onSubmit: async (values) => {
            try {
                await Mob_users.edit_users(edit_user.id, values)
                handleClickClose("edit")
                initData(currentSize, currentPage, searchTerm)
            } catch (error) {
                const errorCode = JSON.stringify(error.response?.data?.status)
                if (errorCode === "401") {
                    window.location.replace('/panel/logout')
                } else if (errorCode === "403") {
                    window.location.reload()
                } else {
                    console.error('An unexpected error occurred:', error)
                }
            }
        }
    })

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Dashboard" breadcrumbItem="Mobile User Settings" />
                    <Row className="mb-3">
                        <ButtonContainer
                            isButtonDelete={true}
                            handleClickDeleteSelected={handleClickDeleteSelected}
                        />
                        <SearchContainer
                            isFilter={true}
                            searchTerm={searchTerm}
                            fieldName={""}
                            handleSearch={handleSearch}
                        />
                    </Row>
                    <DataTableContainer
                        columns={columns}
                        data={filtered}
                        currentPage={currentPage}
                        perPage={perPage}
                        handlePageChange={handlePageChange}
                        searchTerm={searchTerm}
                        handleSearch={handleSearch}
                    />
                    <Pagination
                        isPageSize={true}
                        isPagination={true}
                        currentPage={currentPage}
                        totalPages={perPage}
                        onPageChange={handlePageChange}
                        onSizeChange={handleSizeChange}
                    />
                </Container>
                <Modal
                    isOpen={topup_modal}
                    toggle={() => {
                        handleClickTopup()
                    }}
                    backdrop={'static'}
                    id="staticBackdrop"
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">
                            Topup
                        </h5>
                        <button
                            type="button"
                            onClick={() =>
                                handleClickClose("topup")
                            }
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault()
                            validationTopupType.handleSubmit()
                            return false;
                        }}
                    >
                        <div className="modal-body">
                            <div className="row">
                                <Col xl="3">
                                    ID
                                </Col>
                                <Col xl="1" style={{ textAlign: "right" }}>
                                    :
                                </Col>
                                <Col xl="4">
                                    {topup_user.id}
                                </Col>
                            </div>
                            <div className="row">
                                <Col xl="3">
                                    Full Name
                                </Col>
                                <Col xl="1" style={{ textAlign: "right" }}>
                                    :
                                </Col>
                                <Col xl="4">
                                    {topup_user.full_name}
                                </Col>
                            </div>
                            <div className="row">
                                <Col xl="3">
                                    Email
                                </Col>
                                <Col xl="1" style={{ textAlign: "right" }}>
                                    :
                                </Col>
                                <Col xl="4">
                                    {topup_user.email}
                                </Col>
                            </div>
                            <div className="row">
                                <Col xl="3">
                                    Phone
                                </Col>
                                <Col xl="1" style={{ textAlign: "right" }}>
                                    :
                                </Col>
                                <Col xl="4">
                                    {topup_user.phone}
                                </Col>
                            </div>
                            <div className="row mb-3">
                                <Col xl="3">
                                    Saldo User
                                </Col>
                                <Col xl="1" style={{ textAlign: "right" }}>
                                    :
                                </Col>
                                <Col xl="4">
                                    {numberFormat(topup_user.balance)}
                                </Col>
                            </div>
                            <div className="row">
                                <Col xl="3">
                                    Hutang ?
                                </Col>
                                <Col xl="1" style={{ textAlign: "right" }}>
                                    :
                                </Col>
                                <Col xl="4">
                                    <div className="form-check form-switch mb-3" dir="ltr">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="is_debt"
                                            name="is_debt"
                                            value={validationTopupType.values.is_debt || false}
                                            onChange={(e) => {
                                                validationTopupType.setFieldValue("is_debt", e.target.checked);
                                            }}
                                        />
                                    </div>
                                </Col>
                            </div>
                            <div className="row">
                                <Col xl="3">
                                    Nominal Topup
                                </Col>
                                <Col xl="1" style={{ textAlign: "right" }}>
                                    :
                                </Col>
                                <Col xl="4">
                                </Col>
                            </div>
                            <div className="mb-3">
                                <Input
                                    type="hidden"
                                    name="id"
                                    id="id"
                                    value={validationTopupType.values.id || ''}
                                    onChange={validationTopupType.handleChange}
                                    required
                                />
                                <Input
                                    type="hidden"
                                    name="saldo_user"
                                    id="saldo_user"
                                    value={validationTopupType.values.saldo_user || ''}
                                    onChange={validationTopupType.handleChange}
                                    required
                                />
                                <Input
                                    type="hidden"
                                    name="phone"
                                    id="phone"
                                    value={validationTopupType.values.phone || ''}
                                    onChange={validationTopupType.handleChange}
                                    required
                                />
                                <Input
                                    type="text"
                                    name="topup"
                                    id="topup"
                                    placeholder="Enter nominal topup"
                                    maxLength={10}
                                    value={amount}
                                    onChange={handleChangeTopup}
                                    required
                                />
                                <p style={{ color: 'red', fontSize: '13px' }}>*Maksimal nominal topup yang dibolehkan : {numberFormat(parsedAmount - topup_user.balance)}</p>
                            </div>
                            <div className="modal-footer">
                                <button className="btn btn-primary" type="submit">Topup</button>
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    onClick={() =>
                                        handleClickClose("topup")
                                    }
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </Form>
                </Modal>
                <Modal
                    isOpen={view_modal}
                    toggle={() => {
                        handleClickView()
                    }}
                    backdrop={'static'}
                    id="staticBackdrop"
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">
                            View
                        </h5>
                        <button
                            type="button"
                            onClick={() =>
                                handleClickClose("view")
                            }
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <Col xl="3">
                                ID
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="7">
                                {view_user.id}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="3">
                                Full Name
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="7">
                                {view_user.full_name}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="3">
                                Email
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="7">
                                {view_user.email}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="3">
                                Phone
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="7">
                                {view_user.phone}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="3">
                                Photo
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="7">
                                {view_user.photo}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="3">
                                Role Name
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="7">
                                {view_user.role_name}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="3">
                                Status
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="7">
                                {view_user.status}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="3">
                                Created Date
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="7">
                                {Moment(view_user.created_date).utc().format('yyyy-MM-DD hh:mm:ss')}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="3">
                                Updated Date
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="7">
                                {Moment(view_user.updated_date).utc().format('yyyy-MM-DD hh:mm:ss')}
                            </Col>
                            <br /><br />
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={() =>
                                    handleClickClose("view")
                                }
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </Modal>
                <Modal
                    isOpen={edit_modal}
                    toggle={() => {
                        handleClickEdit()
                    }}
                    backdrop={'static'}
                    id="staticBackdrop"
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">
                            Edit
                        </h5>
                        <button
                            type="button"
                            onClick={() =>
                                handleClickClose("edit")
                            }
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault()
                            validationEditType.handleSubmit()
                            return false;
                        }}
                    >
                        <div className="modal-body">
                            <div className="mb-3">
                                <Label className="form-label">Full Name</Label>
                                <Input
                                    type="text"
                                    name="full_name"
                                    id="full_name"
                                    placeholder="Enter full name"
                                    value={validationEditType.values.full_name || ''}
                                    onChange={validationEditType.handleChange}
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <Label className="form-label">Email</Label>
                                <Input
                                    type="text"
                                    name="email"
                                    id="email"
                                    placeholder="Enter email"
                                    value={validationEditType.values.email || ''}
                                    onChange={validationEditType.handleChange}
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <Label className="form-label">Phone</Label>
                                <Input
                                    type="text"
                                    name="phone"
                                    id="phone"
                                    placeholder="Enter phone"
                                    value={validationEditType.values.phone || ''}
                                    onChange={validationEditType.handleChange}
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <Label className="form-label">Password</Label>
                                <Input
                                    type="text"
                                    name="password"
                                    id="password"
                                    placeholder="Enter password"
                                    value={validationEditType.values.password || ''}
                                    onChange={validationEditType.handleChange}
                                />
                            </div>
                            <div className="mb-3">
                                <Label className="form-label">Security Code</Label>
                                <Input
                                    type="text"
                                    name="pin"
                                    id="pin"
                                    placeholder="Enter pin"
                                    value={validationEditType.values.pin || ''}
                                    onChange={validationEditType.handleChange}
                                />
                            </div>
                            <div className="mb-3">
                                <Label className="form-label">Photo</Label>
                                <Input
                                    type="text"
                                    name="photo"
                                    id="photo"
                                    placeholder="Enter photo"
                                    value={validationEditType.values.photo || ''}
                                    onChange={validationEditType.handleChange}
                                />
                            </div>
                            <div className="mb-3">
                                <Label className="form-label">Roles</Label>
                                <Input
                                    type="select"
                                    name="roles_id"
                                    id="roles_id"
                                    value={validationEditType.values.roles_id || ''}
                                    onChange={changeEdit}
                                    required
                                >
                                    <option value="">Select Roles</option>
                                    {data_roles.map((roles, index) => (
                                        <option value={roles.id} key={index}>{roles.role_name}</option>
                                    ))}
                                </Input>
                            </div>
                            <div className="mb-3">
                                <Label className="form-label">Status</Label>
                                <Input
                                    type="select"
                                    name="is_active"
                                    id="is_active"
                                    value={validationEditType.values.is_active || ''}
                                    onChange={changeEdit}
                                    required
                                >
                                    <option value="">Select Status</option>
                                    <option value="1">Not Verified</option>
                                    <option value="2">Verified</option>
                                    <option value="3">Blocked</option>
                                </Input>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-primary" type="submit">Update</button>
                            <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={() =>
                                    handleClickClose("edit")
                                }
                            >
                                Close
                            </button>
                        </div>
                    </Form>
                </Modal>
            </div >
        </React.Fragment >
    )
};

export default UserSettings;
