import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import { Card, CardBody, CardHeader, CardTitle, Col, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown, Input, Label, Modal, Form } from 'reactstrap';
import Moment from "moment"
import { useFormik } from "formik"

import SimpleBar from "simplebar-react"
import dashboard from "../../services/DashboardService"

const RecentActivity = () => {

    const [data, setDataDebt] = useState([]);
    const [pembayaran_modal, setpembayaran_modal] = useState(false)
    const [pembayaran, setPembayaran] = useState("")
    const [amount, setAmount] = useState('');

    const numberFormat = (value) =>
        new Intl.NumberFormat('id-ID', {
            style: 'currency',
            currency: 'IDR'
        }).format(value).replace(',00', '');

    const handleChangePayment = (e) => {
        const rawValue = e.target.value;
        const formattedValue = formatCurrency(rawValue);
        setAmount(formattedValue);
        const amountReplace = formattedValue.replace(/\./g, '');
        validationPembayaranType.setFieldValue("amount", amountReplace)
    }

    const formatCurrency = (value) => {
        const numericValue = value.replace(/[^0-9]/g, '');
        const formattedValue = Number(numericValue).toLocaleString('id-ID');
        return formattedValue;
    };

    useEffect(() => {
        const controller = new AbortController()
        dashboard.findHutangMember().then(
            (response) => {
                var debt = [];
                response.data.forEach(data => {
                    debt.push(
                        <li className="activity-list" key={data.users_id}>
                            <div className="activity-icon avatar-md">
                                <span className="avatar-title bg-primary-subtle text-primary rounded-circle">
                                    <i className="bx bx-user font-size-24"></i>
                                </span>
                            </div>
                            <div className="timeline-list-item">
                                <div className="d-flex">
                                    <div className="flex-grow-1 overflow-hidden me-4">
                                        <h5 className="font-size-12 mb-1">
                                            {Moment(data.last_debt_date).utc().format('DD-MM-YYYY')}
                                        </h5>
                                        <p className="text-truncate text-muted font-size-13">
                                            {data.full_name}
                                        </p>
                                    </div>
                                    <div className="flex-shrink-0 text-end me-3 d-flex align-items-center">
                                        <h5 className="mb-1 font-size-12" style={{ marginTop: "-15px" }}><b>{numberFormat(data.remaining_debt)}</b></h5>
                                    </div>

                                    <div className="flex-shrink-0 text-end">
                                        <UncontrolledDropdown>
                                            <DropdownToggle
                                                className="text-muted font-size-24"
                                                tag="a"
                                            >
                                                <i className="mdi mdi-dots-vertical"></i>
                                            </DropdownToggle>

                                            <DropdownMenu className="dropdown-menu-end">
                                                <DropdownItem to="#" onClick={() => { handleClickPembayaran(data) }}>Pembayaran</DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </div>
                                </div>
                            </div>
                        </li>
                    )
                })
                setDataDebt(debt)
                return Promise.resolve();
            },
            (error) => {
                const errorCode = JSON.stringify(error.response.data.status);
                if (errorCode.toString() === "401") {
                    window.location.replace('/panel/logout')
                } else if (errorCode.toString() === "403") {
                    window.location.reload()
                }
            }
        )
        return () => controller.abort()
    }, [])

    function handleClickClose(action) {
        if (action === "pembayaran") {
            setpembayaran_modal(false)
        }
    }

    function removeBodyCss() {
        document.body.classList.add("no_padding")
    }

    const handleClickPembayaran = (data) => {
        setPembayaran(data)
        setpembayaran_modal(!pembayaran_modal)
        validationPembayaranType.resetForm()
        removeBodyCss()
    };

    const validationPembayaranType = useFormik({
        enableReinitialize: true,
        initialValues: {
            users_id: pembayaran.users_id,
            full_name: pembayaran.full_name,
            amount: ""
        },
        onSubmit: async (values, { resetForm }) => {
            try {
                await dashboard.add_pembayaran(values)
                handleClickClose("pembayaran")
                window.location.reload()
                resetForm()
            } catch (error) {
                const errorCode = JSON.stringify(error.response?.data?.status)
                if (errorCode === "401") {
                    window.location.replace('/panel/logout')
                } else if (errorCode === "403") {
                    window.location.reload()
                } else {
                    console.error('An unexpected error occurred:', error)
                }
            }
        }
    })

    return (
        <React.Fragment>
            <Col xl={4}>
                <Card>
                    <CardHeader className="align-items-center d-flex">
                        <h4 className="card-title mb-0 flex-grow-1">Hutang Member</h4>
                    </CardHeader>

                    <CardBody className="px-0">
                        <SimpleBar className="px-3" style={{ height: "300px" }}>
                            <ul className="list-unstyled activity-wid mb-0">
                                {data}
                            </ul>
                        </SimpleBar>
                    </CardBody>
                </Card>
            </Col>
            <Modal
                isOpen={pembayaran_modal}
                toggle={() => {
                    handleClickPembayaran()
                }}
                backdrop={'static'}
                id="staticBackdrop"
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0">
                        Pembayaran
                    </h5>
                    <button
                        type="button"
                        onClick={() =>
                            handleClickClose("pembayaran")
                        }
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <Form
                    onSubmit={(e) => {
                        e.preventDefault()
                        validationPembayaranType.handleSubmit()
                        return false;
                    }}
                >
                    <div className="modal-body">
                        <div className="mb-3">
                            <Label className="form-label">Member Name</Label>
                            <Input
                                type="text"
                                name="users_id"
                                id="users_id"
                                placeholder="Enter users id"
                                value={validationPembayaranType.values.users_id || ''}
                                onChange={validationPembayaranType.handleChange}
                                required
                                hidden
                            />
                            <Input
                                type="text"
                                name="full_name"
                                id="full_name"
                                placeholder="Enter full name"
                                value={validationPembayaranType.values.full_name || ''}
                                onChange={validationPembayaranType.handleChange}
                                style={{ backgroundColor: 'lightgray' }}
                                required
                                disabled
                            />
                        </div>
                        <div className="mb-3">
                            <Label className="form-label">Jumlah Bayar</Label>
                            <Input
                                type="text"
                                name="amount"
                                id="amount"
                                placeholder="Enter amount"
                                maxLength={10}
                                value={amount}
                                onChange={handleChangePayment}
                                required
                            />
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button className="btn btn-primary" type="submit">Proses Bayar</button>
                        <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={() =>
                                handleClickClose("pembayaran")
                            }
                        >
                            Close
                        </button>
                    </div>
                </Form>
            </Modal>
        </React.Fragment >
    );
}

export default RecentActivity;