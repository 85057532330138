import { axiosApi, API_URL, API_URL_H2H } from "../helpers/api_helper"
import authHeader from "../helpers/AuthHeader"

const getHistoryTopupNotify = () => {
    return axiosApi
        .get(API_URL + "history_topup_notify", { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const findSaldoH2h = () => {
    return axiosApi
        .get(API_URL_H2H + "get_saldo", { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const findSaldoMember = () => {
    return axiosApi
        .get(API_URL + "get_saldo_users", { headers: authHeader })
        .then((response) => {
            return response.data;
        });
}

const findSaldoInfaqMasjid = (start_date, end_date) => {
    return axiosApi
        .get(API_URL + "get_saldo_infaq_mosque?start_date=" + start_date + "&end_date=" + end_date, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
}

const findSaldoTersalurkan = (start_date, end_date) => {
    return axiosApi
        .get(API_URL + "get_saldo_tersalurkan?start_date=" + start_date + "&end_date=" + end_date, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
}

const findTransactionSuccess = (start_date, end_date) => {
    return axiosApi
        .get(API_URL + "total_transaction_success?start_date=" + start_date + "&end_date=" + end_date, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
}

const findTransactionFailed = (start_date, end_date) => {
    return axiosApi
        .get(API_URL + "total_transaction_failed?start_date=" + start_date + "&end_date=" + end_date, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
}

const findProfits = (start_date, end_date) => {
    return axiosApi
        .get(API_URL + "total_profits?start_date=" + start_date + "&end_date=" + end_date, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
}

const findMemberVerify = () => {
    return axiosApi
        .get(API_URL + "total_user_verify", { headers: authHeader })
        .then((response) => {
            return response.data;
        });
}

const findMemberNotVerify = () => {
    return axiosApi
        .get(API_URL + "total_user_not_verify", { headers: authHeader })
        .then((response) => {
            return response.data;
        });
}

const findHutangMember = () => {
    return axiosApi
        .get(API_URL + "total_hutang_piutang", { headers: authHeader })
        .then((response) => {
            return response.data;
        });
}

const add_pembayaran = (bodyParam) => {
    return axiosApi
        .post(API_URL + "bayar_hutang", bodyParam, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
}

const dashboard = {
    getHistoryTopupNotify,
    findSaldoH2h,
    findSaldoMember,
    findSaldoInfaqMasjid,
    findSaldoTersalurkan,
    findTransactionSuccess,
    findTransactionFailed,
    findProfits,
    findMemberVerify,
    findMemberNotVerify,
    findHutangMember,
    add_pembayaran,
    API_URL,
    API_URL_H2H,
};

export default dashboard;